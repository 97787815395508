import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/mainHomePages/LoginPage.vue";
import LandingPage from "@/views/mainHomePages/LandingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: LandingPage,
    children: [
      {
        path: "/Teams",
        name: "Teams",
        component: () => import("@/views/childHomePages/Teams.vue"),
      },
      {
        path: "/New-team-requests",
        name: "New-team-requests",
        component: () => import("@/views/childHomePages/NewTeamRequests.vue"),
      },
      {
        path: "/Modules",
        name: "Modules",
        component: () => import("@/views/childHomePages/Modules.vue"),
      },
      {
        path: "/Packages",
        name: "Packages",
        component: () => import("@/views/childHomePages/Packages.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
