<template>
  <v-overlay :value="overlay" opacity="0.2">
    <v-img max-width="150px" src="@/assets/loading-animation.gif"></v-img>
    <div class="secondary--text text-center">Please wait...</div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
