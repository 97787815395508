import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    get_current_user_details: {},
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details(state) {
      return state.get_current_user_details;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, userDetails) {
      state.get_current_user_details = userDetails;
    },
  },
  actions: {},
  modules: {},
});
