import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";

Vue.config.productionTip = false;

Amplify.configure({
  Auth: {
    userPoolId: "ap-northeast-2_QULq1LS8O",
    region: "ap-northeast-2",
    userPoolWebClientId: "5vmk2b69ug49uv33vkg090fp8p",
  },
  API: {
    aws_project_region: "ap-northeast-2",
    aws_appsync_graphqlEndpoint: "https://efvlgbfaazhujcb5zlylhnyb3u.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    aws_appsync_region: "ap-northeast-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    // endpoints: [
    //   {
    //     name: "Report_issue",
    //     endpoint:
    //       " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
    //   },
    // ],
  },
});
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
