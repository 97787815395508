/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const GetPendingSignUpRequests = /* GraphQL */ `
  query GetPendingSignUpRequests($input: GetPendingSignUpRequestsInput!) {
    GetPendingSignUpRequests(input: $input)
  }
`;
export const ListOrganisations = /* GraphQL */ `
  query ListOrganisations($input: ListOrganisationsInput!) {
    ListOrganisations(input: $input)
  }
`;
export const ListModules = /* GraphQL */ `
  query ListModules($input: ListModulesInput!) {
    ListModules(input: $input)
  }
`;
export const ListSubModules = /* GraphQL */ `
  query ListSubModules($input: ListSubModulesInput!) {
    ListSubModules(input: $input)
  }
`;
export const ListPackages = /* GraphQL */ `
  query ListPackages($input: ListPackagesInput!) {
    ListPackages(input: $input)
  }
`;
export const ListSubSubModules = /* GraphQL */ `
  query ListSubSubModules($input: ListSubSubModulesInput!) {
    ListSubSubModules(input: $input)
  }
`;
export const ListSubSubSubModules = /* GraphQL */ `
  query ListSubSubSubModules($input: ListSubSubSubModulesInput!) {
    ListSubSubSubModules(input: $input)
  }
`;
